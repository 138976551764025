import React, { useState } from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  makeStyles,
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

const Page = () => {
  const classes = useStyles();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // TODO validate recaptcha?
  // eslint-disable-next-line no-unused-vars
  // const [token, setToken] = useState('');
  const [notification, setNotification] = useState('');
  const [alertType, setAlertType] = useState('success');

  const email = useFormInput('');
  const name = useFormInput('');
  const subject = useFormInput('');
  const message = useFormInput('');
  const inquiryType = useFormInput('info');

  const emailVal = email.value;
  const nameVal = name.value;
  const subjectVal = subject.value;
  const messageVal = message.value;
  const inquiryTypeVal = inquiryType.value;

  const handleSubmit = async (event) => {
    event.preventDefault(); // prevent the page from reloading on form submit

    // check if the captcha was skipped or not
    // if (!executeRecaptcha) {
    //   return;
    // }

    // handle empty fields just in case
    if (!emailVal || !messageVal || !subjectVal || !nameVal) {
      setNotification(`Please don't leave any of the fields empty.`);
      setAlertType('error');

      return;
    }

    // this is the same as grecaptcha.execute on traditional html script tags
    // const result = await executeRecaptcha('homepage');
    // setToken(result);

    // POST request to aws api gateway
    await fetch('https://mwzt1shsja.execute-api.us-east-1.amazonaws.com/prod', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        name: nameVal,
        email: emailVal,
        subject: subjectVal,
        message: messageVal,
        inquiryType: inquiryTypeVal,
      }),
    }).then(
      () => {
        setNotification('Message sent');
        setAlertType('success');
      },
      (error) => {
        setNotification('Message not sent. Try again later');
        setAlertType('error');
        // eslint-disable-next-line no-console
        console.log(error);
      }
    );
  };

  return (
    <Layout container="md">
      <SEO title="Contact Us" />
      <Box textAlign="center" mb={6}>
        <Typography variant="h1">Contact Us</Typography>
      </Box>

      <Box mb={8}>
        <Divider />
      </Box>

      <div className={classes.paper}>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          method="POST"
          onSubmit={handleSubmit}
        >
          <TextField
            variant="outlined"
            id="name"
            label="Name"
            name="name"
            type="text"
            margin="normal"
            required
            fullWidth
            {...name}
          />
          <TextField
            variant="outlined"
            id="email"
            label="Email"
            name="email"
            type="email"
            margin="normal"
            required
            fullWidth
            {...email}
          />
          <TextField
            variant="outlined"
            id="subject"
            label="Subject"
            type="text"
            margin="normal"
            required
            fullWidth
            {...subject}
          />
          <TextField
            variant="outlined"
            multiline
            rows={6}
            rowsMax={12}
            id="message"
            label="Message"
            name="message"
            type="textarea"
            margin="normal"
            required
            fullWidth
            {...message}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Inquiry Type:</FormLabel>
            <RadioGroup
              row
              aria-label="Inquiry Type"
              name="inquiryType"
              {...inquiryType}
            >
              <FormControlLabel
                value="info"
                control={<Radio />}
                label="General Inquiry"
              />
              <FormControlLabel
                value="web"
                control={<Radio />}
                label="Website Issue"
              />
              <FormControlLabel
                value="personal"
                control={<Radio />}
                label="Personal Request"
              />
            </RadioGroup>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
          {notification && (
            <span>
              <Alert severity={alertType}>{notification}</Alert>
            </span>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default Page;
